//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import male_avatar from "@/assets/avatatr_man.png";
import female_avatar from "@/assets/avatar_female.png";
import male from "@/assets/customer/male.png";
import female from "@/assets/customer/female.png";
import filter from "@/assets/customer/filter.png";
import { LocalStorage } from 'quasar'
export default {
  name: "Index",
  data() {
    return {
      mobile: "",
      memberInfo: {},
      memberList: [],
      filterPop: false,
      acitvities: [
      { label: "小于30天", value: "0,29", selected: false },
        { label: "30-90", value: "30,90", selected: false },
        { label: "91~180天", value: "91,180", selected: false },
        { label: "181~365天", value: "181,365", selected: false },
        { label: "大于365天", value: "365,21900", selected: false },
      ],
      levels: [
        { label: "凤凰会员", value: "ZB24", selected: false },
        { label: "金鸟会员", value: "ZB23", selected: false },
        { label: "银鸟会员", value: "ZB22", selected: false },
        { label: "普通会员", value: "ZB21", selected: false },
        // {label: 'V1', value: 'ZB00', selected: false},
        // {label: 'V2', value: 'ZB01', selected: false},
        // {label: 'V3', value: 'ZB02', selected: false},
        // {label: 'V4', value: 'ZB03', selected: false},
      ],
      others: [
        { label: "本月生日会员", value: "current", selected: false },
        { label: "次月生日会员", value: "next", selected: false },
         {label: '新会员未复购',value: "newunsale",selected: false},
      ],
      loading: false,
      birthday: "",
      begCrDate: "",
      endCrDate: "",
      level: "",
      newunsalse:"",
      original: [],
      male,
      female,
      male_avatar,
      female_avatar,
      filter,
      pageData: {
        pageIndex: 0,
        pageSize: 50,
        rowsCount: 0,
        start: 0,
      },
      queryMap: {},

      finished:false,
      originalShop:[],
      shopMemberList:[],
      shopLoading:false,
      shopFinished:false
    };
  },
  computed: {
    moreList() {
      var result = true;
      if (
        (this.pageData.pageIndex + 1) * this.pageData.pageSize >
        this.pageData.rowsCount
      ) {
        result = false;
      }
      return result;
    },
  },
  mounted() {
    window.document.title = "我的会员";
  },
  activated() {
    window.document.title = "我的会员";
  },
  created() {
    window.document.title = "我的会员";
    let value = "0,30";
    let days = value.split(",");
    let begin = this.$moment()
      .subtract(Number(days[1]), "day")
      .format("yyyyMMDD");

    console.log(begin);
    this.getMemberListv2()
  },
  methods: {
    searchMobile() {
      let searchMobile = this.mobile;
      let temp = [];
      const original = this.original;
      if (searchMobile && searchMobile.trim() != "") {
        original.forEach((item) => {
          if (
            item.MOBILE.toString().indexOf(searchMobile) > -1 ||
            (item.NAME && item.NAME.indexOf(searchMobile) > -1) ||
            (item.PHONE && item.PHONE.indexOf(searchMobile) > -1)
          ) {
            temp.push(item);
          }
        });
      } else {
        this.shopMemberList = []
        temp = JSON.parse(JSON.stringify(original));
      }
      this.memberList = temp;
      if(temp.length == 0 && searchMobile && searchMobile.trim() != ""){
        this.getShopMemberByMobile()
      }
      console.log("searchMobile", this.memberList);
    },
    getMemberListv2(){
      let shopBrand=LocalStorage.getItem('shopBrandCode').brand.split(',');
      var pdata = [{
                'PARAMETER': 'Z0CALDAY_MVSM_003',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW':  this.$moment().format("yyyyMMDD"),
                "LOW_FLAG":"0",
                'HIGH': ''
                
            }, {
                'PARAMETER': 'Z0INDUSTRY_MVOM_002',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW': shopBrand[0],
                "LOW_FLAG":"1",
                'HIGH': ''
            }, {
                'PARAMETER': 'ZCORPID_CMOM_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                "LOW_FLAG":"1",
                'LOW': 'wxee07e0adf516838a',
                'HIGH': ''
            }, {
                'PARAMETER': 'ZUSERID_CMOO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                "LOW_FLAG":"1",
                'LOW': LocalStorage.getItem('userId'),
                'HIGH': ''
            }]
      
      //   let shopBrand=LocalStorage.getItem('shopBrandCode').brand.split(',');
      // let shopBrand=['ZHAZ']
      //    shopBrand.map((brand)=>{
      //      pdata.push({
      //           'PARAMETER': 'Z0INDUSTRY_MVOM_002',
      //           'SIGN': 'I',
      //           'OPTION': 'EQ',
      //           'LOW': brand,
      //           "LOW_FLAG":"1",
      //           'HIGH': ''
      //       })
      //    })
            if(this.level!=''){  //会员层级
              
              let levelArr=this.level.split(',');
              console.log(levelArr);
              levelArr.map((item)=>{
                pdata.push({
                'PARAMETER': 'ZMSHLVL_CMOO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                "LOW_FLAG":"1",
                'LOW': item,
                'HIGH': ''
            })
              })
 
            }
            if(this.begCrDate!=''&&this.endCrDate!=''){ //消费日期

              pdata.push({
                'PARAMETER': 'ZLSDATE_CMIO_001',
                'SIGN': 'I',
                'OPTION': 'BT',
                'LOW': this.begCrDate,
                "LOW_FLAG":"1",
                'HIGH': this.endCrDate
            })
            }

      if(this.birthday!=""){
        console.log(this.birthday)
        let birthArr=this.birthday.split(',');
        if(birthArr.length==1){
          pdata.push({
                'PARAMETER': 'ZBIRMONTH_CMIO_001',
                'SIGN': 'I',
                'OPTION': 'BT',
                'LOW': birthArr[0],
                "LOW_FLAG":"1",
                'HIGH': birthArr[0]
            })
        }
        if(birthArr.length==2){
          pdata.push({
                'PARAMETER': 'ZBIRMONTH_CMIO_001',
                'SIGN': 'I',
                'OPTION': 'BT',
                'LOW': birthArr[0],
                "LOW_FLAG":"1",
                'HIGH': birthArr[1]
            })
        }
      } 

      if(this.newunsalse=='A'){
        pdata.push({
                'PARAMETER': 'ZFLAG3_CMOO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                "LOW_FLAG":"1",
                'LOW': this.newunsalse,
                'HIGH': ''
            })
      }
    this.loading=true
    this.finished=false;
    this.memberList = [];
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer +
            "/bw/BwApi/getExternalcontactList",
          pdata
        )
        .then((res) => {
          console.log("getCustomerPie", res.data);
          if (res && res.data && res.data.data) {
            const resultData=res.data.data;
            this.finished=true;
            resultData.map((item)=>{
              if(item.ZBIRTHDAY=='00000000'){
                item.birthday='';
              }else{
                item.birthday=this.$moment(item.ZBIRTHDAY).format('MM-DD');
              }
              if(item.ZLSDATE=='00000000'){
                item.consumeDate='从未消费';
              }else{
                item.consumeDate=Math.abs(
                          this.$moment(item.ZLSDATE).diff(this.$moment(), "days")
                        ) + "天未消费"
              }
              if(item.ZETDATE=='00000000'){
                item.createTime='未注册'
              }
              else{
                item.createTime=this.$moment(item.ZETDATE).format('YYYY-MM-DD')
              }
            })
            this.original=resultData
            this.searchMobile()
            
          }  else {
            that.original = [];
            that.memberList = [];
          }
          this.loading = false;
        });

    },
    getShopMemberByMobile(){
      let that = this
      let shops=LocalStorage.getItem('userOwnerShops').split(',');
      let shopBrand=LocalStorage.getItem('shopBrandCode').brand.split(',');
      var pdata = [{
                'PARAMETER': 'Z0CALDAY_MVIM_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW':  this.$moment().format("yyyyMMDD"),
                "LOW_FLAG":"1",
                'HIGH': this.$moment().format("yyyyMMDD"),
                "HIGH_FLAG":"1"
                
            }, {
                'PARAMETER': 'Z0INDUSTRY_MVMM_003',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW': shopBrand[0],
                "LOW_FLAG":"0",
                'HIGH': ''
            },  {
                'PARAMETER': 'Z0CUSTOMER_MVMO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                "LOW_FLAG":"0",
                'LOW': shops[0],
                'HIGH': ''
            },  {
                'PARAMETER': 'ZTEL_CMMO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                "LOW_FLAG":"0",
                'LOW': this.mobile && this.mobile.trim(),
                'HIGH': ''
            }]
    this.shopLoading=true
    this.shopFinished=false;
    this.shopMemberList = [];
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer +
            "/bw/BwApi/qywx_getMemberListByStoreId",
          pdata
        )
        .then((res) => {
          console.log("qywx_getMemberListByStoreId", res.data);
          if (res && res.data && res.data.data) {
            const resultData=res.data.data;
            this.shopFinished=true;
            resultData.map((item)=>{
              item.birthday=this.$moment(item.birthday).format('MM-DD');
              if(item.last_trade_date=='00000000'){
                item.consumeDate='从未消费';
              }else{
                item.consumeDate=Math.abs(
                          this.$moment(item.last_trade_date).diff(this.$moment(), "days")
                        ) + "天未消费"
              }
              if(item.join_date=='00000000'){
                item.createTime='未注册'
              }
              else{
                item.createTime=this.$moment(item.join_date).format('YYYY-MM-DD')
              }
            })
            that.originalShop = [];
            that.shopMemberList=resultData
          } else {
            that.originalShop = [];
            that.shopMemberList = [];
          }
          this.shopLoading = false;
        });

    },
    getMemberList() {

      const that = this;
     // this.memberList = [];
      this.pageData.pageIndex++;
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer + "/v1/wx/cp/externalcontact/list",
          {
            // this.axios.post('/v1/wx/cp/crm/store/getExclusiveCustomers', {
            pager: this.pageData,
            queryMap: {
              // "guideID": "",
              // "brand": brand,
              birthday: this.birthday,
              begCrDate: this.begCrDate,
              endCrDate: this.endCrDate,
              crmLevelName: this.level,
              brand: "ZBXN",
              // "level": 'ZB24'
            },
          }
        )
        .then((res) => {
          if (res && res.data) {
            console.log("data", res.data);
            if (res.data.data && res.data.data.list.length > 0) {
              var pageData=res.data.data.page;
              if((pageData.pageIndex + 1) * pageData.pageSize >pageData.rowsCount){
                      this.finished=true;
              }
              console.log(res.data);
              res.data.data.list.map((v) => {
                if (v.remarkMobiles == "[]") {
                  v.remarkMobiles = "";
                } else {
                  v.remarkMobiles = JSON.parse(v.remarkMobiles);
                }
                
                 if (v.tags) {
                  v.tags = v.tags.split(",");
                  if (v.tags.length >= 3) {
                    v.gradeName = v.tags[0] || "未注册";
                    v.consumeDate =
                      v.tags[2] && v.tags[2].indexOf("00:00:00") > -1
                        ? Math.abs(
                          that.$moment(v.tags[2]).diff(that.$moment(), "days")
                        ) + "天未消费"
                        : "从未消费";
                    v.birthday = this.$moment(v.tags[v.tags.length - 1]).format(
                      "MM-DD"
                    );
                  } else {
                    v.gradeName = v.tags[0] || "未注册";
                    v.birthday = "";
                    v.consumeDate = "从未消费";
                  }
                }
              });
              that.original =that.original.concat(res.data.data.list);
            } else {
              that.original = [];
              that.memberList = [];
            }

            // if (Array.isArray(that.original)){
            //     that.original.forEach(item=>{
            //         if (item.lastConsumeDate != ''){
            //             item.lastConsumeDate = that.$moment(item.lastConsumeDate).format('yyyy-MM-DD');
            //         }
            //         if (item.mobile != ''){
            //             item.mobileTxt = item.mobile.slice(0, 4) + '****' + item.mobile.slice(-3);
            //         }else {
            //             item.mobileTxt = '';
            //         }
            //     });
            // }else {
            //     that.original = [];
            // }
            //  that.memberList = JSON.parse(JSON.stringify(that.original));
            that.searchMobile();
          } else {
            that.original = [];
            that.memberList = [];
          }
          this.loading = false;
        });
    },
    toDetail(bpid, name,euid) {
      
      if (euid) {
        this.$router.push("/extcustomer/unregister?euid="+euid);
      } else {
        this.$router.push("/extcustomer/list?name="+name + '&bpid='  +bpid);
      }
    },
    toggle(name, index) {
      if (name == "acitvities") {
        // this.acitvities.forEach(item=>{
        //     item.selected = false;
        // });
        // this[name][index]['selected'] = true;
        if (this[name][index].selected) {
          this[name][index].selected = !this[name][index].selected;
        } else {
          this.acitvities.forEach((item) => {
            item.selected = false;
          });
          this[name][index]["selected"] = true;
        }
      } else {
        this[name][index]["selected"] = !this[name][index]["selected"];
      }
    },
    sureFilter() {
      const that = this;
      let act = this.acitvities; //消费时间
      let begin = "",
        end = "";
      that.begCrDate = "";
      that.endCrDate = "";
      that.newunsalse=""
      act.forEach((item) => {
        if (item.selected) {
          let days = item.value.split(",");
          end = this.$moment()
            .subtract(Number(days[0]), "days")
            .format("yyyyMMDD");
          begin = this.$moment()
            .subtract(Number(days[1]), "days")
            .format("yyyyMMDD");
          that.begCrDate = begin;
          that.endCrDate = end;
        }
      });

      let level = this.levels;
      let temp = [];
      level.forEach((item) => {
        if (item.selected) {
          temp.push(item.value);
        }
      });
      this.level = temp.join(",");

      let others = this.others;
      let monthTemp = [];
      others.forEach((item) => {
        if (item.value == "current" && item.selected) {
          monthTemp.push(that.$moment().format("MM"));
        }
        if (item.value == "next" && item.selected) {
          monthTemp.push(that.$moment().add(1, "months").format("MM"));
        }
        if(item.value=='newunsale'&&item.selected){
               that.newunsalse='A'
        }
      });
      this.birthday = monthTemp.join(",");
      this.pageData.pageIndex=0;
      this.original=[]
      this.getMemberListv2();
      this.filterPop = false;
    },
  },
};
