import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=3189a83c&scoped=true&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"
import style0 from "./Index.vue?vue&type=style&index=0&id=3189a83c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3189a83c",
  null
  
)

export default component.exports
import {QPageSticky,QInput,QIcon,QBtn,QCardSection,QSeparator} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QPageSticky,QInput,QIcon,QBtn,QCardSection,QSeparator})
